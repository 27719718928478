<template>
  <div>
    <Modal
      v-model="addVideoModal"
      @ok="SEND_TO_VIDEO_TO_SECTION"
      ref="addVideoModalModal"
      persistent
      :title="$t('editVideo')"
      :hide-footer="getLoadingOfFileSize || storageLimitExceedModal"
    >
      <div v-if="getLoadingOfFileSize" class="text-center">
        <loading></loading>
        <p>{{ $t("pleaseWaitFileSizeCalculating") }}</p>
      </div>
      <module-locked
        v-else-if="storageLimitExceedModal"
        :title="$t('upgradeAccount.storageExceedTitle')"
        :description="$t('upgradeAccount.storageExceedDescription')"
      />
      <section class="vid-title-sec" v-else-if="addVideoModal">
        <div class="container">
          <div
            class="alert"
            :class="`alert-${alertBox.variant}`"
            v-if="alertBox.status"
          >
            {{ alertBox.message }}
          </div>
          <div class="row">
            <div class="col-2">
              <img src="/images/video.svg" width="100%" alt="video" />
            </div>
            <div class="col-10">
              {{ item.section.name }}
              <p>
                <small
                  >{{ item.video.file.name }} ~
                  {{ Math.round(item.video.file.size / 1000000) }} MB</small
                >
              </p>
            </div>
          </div>
          <hr />
          <form id="add-video" @submit.prevent="SET_SUBMIT_VIDEO">
            <div class="vid-title">
              <h2 class="title-hd">{{ $t("videoTitle") }}</h2>
              <div class="form_field">
                <input
                  type="text"
                  maxlength="100"
                  required
                  v-model="form.description"
                  :placeholder="$t('addHere')"
                />
                <InvalidFeedback></InvalidFeedback>
              </div>
            </div>
            <div class="mt-4">
              <h2 class="title-hd">{{ $t("videoDescription") }}</h2>
              <vue-editor
                id="editor"
                :editorToolbar="[
                  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [
                    { align: '' },
                    { align: 'center' },
                    { align: 'right' },
                    { align: 'justify' },
                  ],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                  [{ color: [] }, { background: [] }],
                  ['link'],
                  ['clean'],
                ]"
                v-model="form.overview"
              >
              </vue-editor>
            </div>
            <div class="vid-title mt-4">
              <h2 class="title-hd">
                {{ $t("previewVideoQ") }}
              </h2>
              <div class="bg-light card">
                {{ $t("ifSayYesUserCanWacthVideo") }}
              </div>
              <div class="my-4">
                <button
                  class="btn btn-pill"
                  @click.prevent="form.isPreview = true"
                  :class="{ 'btn-success': form.isPreview }"
                >
                  {{ $t("yes") }}
                </button>
                <button
                  class="btn btn-pill"
                  @click.prevent="form.isPreview = false"
                  :class="{ 'btn-success': !form.isPreview }"
                >
                  {{ $t("no") }}
                </button>
              </div>
            </div>
            <div class="vid-title mt-4" v-if="isLocked === 1">
              <h2 class="title-hd">
                {{ $t("whenVideoDoneShouldUserWait") }}
              </h2>
              <div class="my-4">
                <button
                  class="btn btn-pill"
                  @click.prevent="showInterval = true"
                  :class="{ 'btn-success': showInterval }"
                >
                  {{ $t("yes") }}
                </button>
                <button
                  class="btn btn-pill"
                  @click.prevent="showInterval = false"
                  :class="{ 'btn-success': !showInterval }"
                >
                  {{ $t("no") }}
                </button>
              </div>
              <h2 class="title-hd" v-if="showInterval">
                {{ $t("howManyDaysUserWait") }}
              </h2>
              <div class="form_field" v-if="showInterval">
                <input
                  type="number"
                  :min="1"
                  :max="30"
                  required
                  v-model="form.interval"
                  placeholder="Gün sayısı girin.."
                />
                <InvalidFeedback></InvalidFeedback>

                <div class="bg-light card mt-4">
                  {{ $t("thisInfoWillShowToUser") }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  </div>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import InvalidFeedback from "../Shared/InvalidFeedback.vue";
import { VueEditor } from "vue2-editor";
import { uploadService } from "vuejs-chunks-upload/dist";
import StorageOfApp from "../../mixins/StorageOfApp";
import ModuleLocked from "../Packages/ModuleLocked.vue";
import { CreateGuid } from "../../helpers/guid";
import Loading from "../Shared/Loading.vue";
export default {
  mixins: [StorageOfApp],
  components: { Modal, InvalidFeedback, VueEditor, ModuleLocked, Loading },
  data() {
    return {
      addVideoModal: false,
      showInterval: false,
      storageLimitExceedModal: false,
      form: {
        description: "",
        interval: 1,
        isPreview: false,
        isActive: 1,
        overview: "",
      },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    item: {
      default: () => {
        return {
          section: {},
          video: {
            file: [],
            uri: "",
            duration: 0,
          },
        };
      },
    },
    productIndex: {
      default: -1,
    },
    isLocked: {
      default: -1,
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    addVideoModal(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
    getLoadingOfFileSize(data) {
      if (!data) {
        setTimeout(() => {
          this.storageLimitExceedModal = this.CHECK_STORAGE_EXCEED();
        }, 250);
      }
    },
  },
  methods: {
    upload(file, fd) {
      this.progress = 0;
      this.result = null;
      uploadService.chunk(
        "https://api.hm.eralpsoftware.net/Videos",
        file,
        // Progress
        (percent) => {
          console.log(percent);
        },
        // Success
        (res) => {
          console.log(res);
        },
        // Error
        (err) => {
          console.log(err);
        },
        fd
      );
    },
    SET_SUBMIT_VIDEO() {
      this.SEND_TO_VIDEO_TO_SECTION(
        this.$refs.addVideoModalModal.SET_IS_LOADING
      );
    },
    CHECK_STORAGE_EXCEED() {
      const totalSize = this.getFileSize.usedSize + this.item?.video?.size || 0;
      const result = this.FIND_PERCENT(totalSize);
      const invalid = result > 100 || isNaN(result);
      // if (invalid)
      //   this.$router.replace({
      //     query: { ...this.$route.query, modal: "closed" },
      //   });
      console.log("invalid: ", invalid);

      return invalid;
    },
    async SEND_TO_VIDEO_TO_SECTION(loading) {
      if (
        this.form.description === "" ||
        (this.showInterval &&
          (this.form.interval < 1 || this.form.interval > 30))
      )
        return;

      // check storage limiti.
      const storageLimitisExceed = this.CHECK_STORAGE_EXCEED();
      if (storageLimitisExceed) {
        this.addVideoModal = false;
        return (this.storageLimitExceedModal = true);
      }
      loading(true);
      //       FileName
      // string
      // IsLastPart
      // boolean
      // Order
      // integer($int32)
      // this.upload(this.item.video.file, fd);
      const videoExtension = this.item.video.file.name.split(".").pop();
      const videoName = this.item.video.file.name
        .replace(`.${videoExtension}`, "")
        .replace(/[^a-zA-Z0-9-]/g, "");

      this.$store.dispatch("SEND_TO_VIDEO_TO_SECTION", {
        file: this.item.video.file,
        productIndex: this.productIndex,
        sectionIndex: this.item.section.index,
        nameOfFile: `${CreateGuid()}-${videoName}.${videoExtension}`,
        form: {
          description: this.form.description,
          overview: this.form.overview,
          videoPath: this.item.video.uri,
          duration: this.item.video.duration,
          isPreview: this.form.isPreview ? 1 : 0,
          size: this.item.video.size,
          isActive: 1,
          sectionId: this.item.section.id,
          interval: this.showInterval ? this.form.interval : 0,
        },
        item: this.item,
        showInterval: this.showInterval,
      });
      setTimeout(() => {
        loading(false);
        this.addVideoModal = false;
      }, 1000);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "add-video-to-section") {
          if (this.item.video.name !== "") {
            // check storage limiti.
            const storageLimitisExceed = this.CHECK_STORAGE_EXCEED();
            if (storageLimitisExceed) {
              this.addVideoModal = true;
              return (this.storageLimitExceedModal = true);
            } else this.addVideoModal = true;
          } else this.addVideoModal = false;
        }
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>
